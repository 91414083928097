















import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import TaxStrategy from '../../components/org/TaxStrategy.vue';
@Component({
  components: {
    TaxStrategy,
  },
})
export default class TaxStrategyClass extends BaseVue {
  public selectedTab = 0;
}
