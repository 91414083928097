


































































import gql from 'graphql-tag';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { BaseVue } from '@/BaseVue';

import { SpendingStrategy, StrategyPriority } from './types';

@Component({
  apollo: {
    taxStrategy: {
      // pollInterval: 5000,
      query: gql`
        query GetOrgTaxStrategy($id: ID!) {
          org(id: $id) {
            id
            taxStrategy {
              specificIdentificationStrategyRanges {
                identificationStrategyRanked {
                  spendingStrategy
                  selectionPreference
                }
              }
            }
          }
        }
      `,
      update(data) {
        return data.org.taxStrategy;
      },
      variables() {
        // Use vue reactive properties here
        return {
          id: this.$store.state.currentOrg.id,
        };
      },
      loadingKey: 'isLoading',
    },
  },
})
export default class TaxStrategy extends BaseVue {
  taxStrategy: any = null;
  strategyRanges: StrategyPriority[] = [];
  selected = [];
  isLoading = 0;
  isSaving = false;
  spendingStrategies = [
    {
      text: 'Short Term Gain',
      value: 'ShortTermGain',
    },
    {
      text: 'Short Term Loss',
      value: 'ShortTermLoss',
    },
    {
      text: 'Long Term Gain',
      value: 'LongTermGain',
    },
    {
      text: 'Long Term Loss',
      value: 'LongTermLoss',
    },
  ];

  selectionPreferences = [
    {
      text: 'Minimize',
      value: 'Minimize',
    },
    {
      text: 'Maximize',
      value: 'Maximize',
    },
  ];

  headers = [
    {
      text: 'Priority',
      align: 'left',
      value: 'priority',
    },
    {
      text: 'Spending Strategy',
      align: 'left',
      value: 'spendingStrategy',
    },
    {
      text: 'Preference',
      align: 'left',
      value: 'selectionPreference',
    },
  ];

  mounted() {
    this.refresh();
    this.strategyRanges = new Array(4)
      .fill(0)
      .map((_) => Object.assign({ spendingStrategy: null, selectionPreference: null }));
  }

  refresh() {
    this.$apollo.queries.taxStrategy.refetch();
  }

  availableSpendingStrategies(index: number) {
    const selectedStrategies: SpendingStrategy[] = [];

    this.strategyRanges.forEach((el: StrategyPriority, index_) => {
      if (el.spendingStrategy && index !== index_) {
        selectedStrategies.push(el.spendingStrategy);
      }
    });
    return this.spendingStrategies.filter((el) => !selectedStrategies.includes(el.value as SpendingStrategy));
  }

  async save() {
    try {
      this.isSaving = true;
      const vars = {
        orgId: this.$store.state.currentOrg.id,
        specificIdentificationStrategyRanges: this.strategyRanges,
      };
      const resp = await this.$apollo.mutate({
        // Query
        mutation: gql`
          mutation updateOrg(
            $orgId: ID!
            $specificIdentificationStrategyRanges: [SpecificIdentificationStrategyInput]!
          ) {
            updateOrg(orgId: $orgId, specificIdentificationStrategyRanges: $specificIdentificationStrategyRanges) {
              id
            }
          }
        `,
        // Parameters
        variables: vars,
      });
      if (resp.errors) {
        this.showErrorSnackbar('Problem: ' + resp.errors.join('<br />'));
      }

      if (resp.data && resp.data.updateOrg) {
        this.showSuccessSnackbar('Saved successfully!');
        this.$emit('complete');
      }
    } catch (err) {
    } finally {
      this.isSaving = false;
    }
  }

  @Watch('taxStrategy')
  onTaxStrategyChanged() {
    this.strategyRanges =
      this.taxStrategy?.specificIdentificationStrategyRanges?.[0]?.identificationStrategyRanked?.map((el: any) => {
        const { __typename, ...otherProps } = el;
        return otherProps;
      }) ?? new Array(4).fill(0).map((_) => Object.assign({ spendingStrategy: null, selectionPreference: null }));
  }
}
